import '@/app/globals.css'
import { PrimaryButton } from '@/app/components/PrimaryButton'
import { ErrorPageWithImage } from '@/app/modules/shared/components/error'
import { useTranslations } from 'next-intl'

export async function getStaticProps(context) {
  return {
    props: {
      messages: (await import(`@/locales/${context.locale}/strings.json`))
        .default,
      locale: context.locale,
    },
  }
}

export default function NotFoundPage() {
  const t = useTranslations('NotFound')

  return (
    <ErrorPageWithImage
      title={t('title')}
      pageTitle={t('pageTitle')}
      description={t('description')}
      mobileImage="/images/not-found-mobile.png"
      desktopImage="/images/not-found-desktop.png"
      alt={t('alt')}
    >
      <PrimaryButton href="/">{t('backToHome')}</PrimaryButton>
    </ErrorPageWithImage>
  )
}
