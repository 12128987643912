import '@/app/globals.css'

import { Header } from '@/app/components/Header'
import { Headline1, P } from '@/app/components/Typography'
import Head from 'next/head'

import resolveConfig from 'tailwindcss/resolveConfig'
import myConfig from '../../../../../../tailwind.config'
import { PropsWithChildren } from 'react'
import { Footer } from '@/app/components/Footer'

type ErrorPageWithImageProps = PropsWithChildren<
  {
    title: string
    pageTitle: string
    description: string
    mobileImage: string
    desktopImage: string
    alt: string
  } & React.HTMLAttributes<HTMLDivElement>
>

export function ErrorPageWithImage({
  title,
  pageTitle,
  description,
  mobileImage,
  desktopImage,
  alt,
  children,
  ...props
}: ErrorPageWithImageProps) {
  const { theme } = resolveConfig(myConfig)

  return (
    <>
      <Head>
        <title>{`${pageTitle} | Mydra`}</title>
      </Head>
      <Header />
      <main
        {...props}
        className={`w-full flex flex-col min-h-screen overflow-x-clip ${props.className}`}
      >
        <div className="container grid grid-cols-4 gap-y-6 py-4 md:grid-cols-12">
          <picture className="col-span-full row-start-1 md:col-span-4 md:col-end-12 md:col-start-7 lg:col-start-8">
            <source
              srcSet={desktopImage}
              media={`(min-width: ${theme.screens.md})`}
            />
            <img src={mobileImage} alt={alt} />
          </picture>
          <div className="flex flex-col col-span-full row-start-2 gap-5 md:row-start-1 md:gap-6 md:pt-20 md:col-end-5 md:col-span-5">
            <Headline1>{title}</Headline1>
            <P className="text-[--text]">{description}</P>
            {children && <div className="mt-3 md:mt-10">{children}</div>}
          </div>
        </div>
        <Footer className="mt-auto w-full" />
      </main>
    </>
  )
}
